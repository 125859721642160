import { SnackbarContext } from 'contexts/SnackbarContext';
import { useContext } from 'react';

const useSnackbar: any = () => useContext(SnackbarContext);

export default useSnackbar;
export {
    useSnackbar
};


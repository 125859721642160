import useApi from 'hooks/useApi';
import { dashboardFVRedirectUri, dashboardProRedirectUri } from 'hooks/usePageProtect';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export default function Index() {
    const router = useRouter();
    const { establishments } = useApi();

    useEffect(() => {
        if (establishments && establishments.length > 0) {
            router.replace(dashboardProRedirectUri);
        } else {
            router.replace(dashboardFVRedirectUri);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router?.isReady, establishments]);
    return (
        <></>
    );
}
